import React from "react"
import cx from "classnames"
import sortBy from "lodash/sortBy"
import jakeRZ from "../images/jake-remy-zero.jpg"
import "./setlist.css"

const setList = [
  {
    artist: "Social Distortion",
    songs: ["Machine Gun Blues", "Bad Luck", "Ring of Fire", "Ball and Chain"],
  },
  { artist: "Lit", songs: ["My Own Worst Enemy"] },
  { artist: "DeerHunter", songs: ["Desire Lines"] },
  { artist: "Silversun Pickups", songs: ["Panic Switch"] },
  { artist: "Smashing Pumpkins", songs: ["Cherub Rock"] },
  { artist: "Iggy And The Stooges", songs: ["Search and Destroy"] },
  { artist: "Ozzy Osbourne", songs: ["Crazy Train"] },
  { artist: "Alice In Chains", songs: ["Man in the Box"] },
  { artist: "Weezer", songs: ["Hash Pipe"] },
  {
    artist: "Cracker",
    songs: ["Low", "Teen Angst (What the World Needs Now)"],
  },
  { artist: "Camper Van Beethoven", songs: ["Take the Skinheads Bowling"] },
  { artist: "AC/DC", songs: ["Dirty Deeds Done Dirt Cheap"] },
  { artist: "Cream", songs: ["Sunshine of Your Love"] },
  { artist: "Pink Floyd", songs: ["Money"] },
  { artist: "Judas Priest", songs: ["Living After Midnight"] },
  { artist: "Oasis", songs: ["Supersonic"] },
  { artist: "Cheap Trick", songs: ["Surrender"] },
  { artist: "Foo Fighters", songs: ["All My Life", "My Hero"] },
  { artist: "Cult", the: true, songs: ["She Sells Sanctuary"] },
  { artist: "Blink 182", songs: ["Dammit, What's My Age Again"] },
  { artist: "Velvet Revolver", songs: ["Slither"] },
  { artist: "Stone Temple Pilots", songs: ["Interstate Love Song", "Plush"] },
  { artist: "Sponge", songs: ["Molly (Sixteen Candles)", "Plowed"] },
  { artist: "JET", songs: ["Cold Hard Bitch", "Are You Gonna Be My Girl"] },
  { artist: "James Gang", songs: ["Walk Away"] },
  {
    artist: "Tom Petty",
    songs: ["American Girl", "Runnin Down A Dream", "You Wreck Me"],
  },
  { artist: "Smithereens", songs: ["Only A Memory"], the: true },
  {
    artist: "Flaming Lips",
    the: true,
    songs: ["She Don't Use Jelly"],
  },
  { artist: "Goo Goo Dolls", the: true, songs: ["Long Way Down"] },
  { artist: "Dandy Warhols", the: true, songs: ["Bohemian Like You"] },
  { artist: "INXS", songs: ["Don't Change"] },
  { artist: "Killers", the: true, songs: ["Mr. Brightside"] },
  {
    artist: "Clash",
    the: true,
    songs: ["Police On My Back", "Brand New Cadillac"],
  },
  { artist: "Red Hot Chili Peppers", songs: ["Fortune Faded"] },
  {
    artist: "Police",
    the: true,
    songs: ["Walking on the Moon", "Message in a Bottle", "Synchronicity II"],
  },
  {
    artist: "Hollies",
    the: true,
    songs: ["Long Cool Woman (In A Black Dress)"],
  },
  { artist: "Badfinger", songs: ["Baby Blue"] },
  { artist: "Band of Horses", songs: ["Is There a Ghost"] },
  { artist: "Cars", the: true, songs: ["Let's Go", "Bye Bye Love"] },
  { artist: "Green Day", songs: ["Holiday"] },
  { artist: "Jimmy Eat World", songs: ["The Middle"] },
  { artist: "Ramones", the: true, songs: ["I Wanna Be Sedated"] },
  { artist: "Go-Go's", the: true, songs: ["Head Over Heels"] },
  {
    artist: "Headstones/Gordon Lightfoot",
    the: true,
    songs: ["The Wreck of the Edmund Fitzgerald"],
  },
  { artist: "Franz Ferdinand", songs: ["Take Me Out"] },
  { artist: "Tool", songs: ["Sober"] },
  { artist: "Screaming Trees", songs: ["Nearly Lost You"] },
  { artist: "Mötley Crüe", songs: ["Don't Go Away Mad (Just Go Away)"] },
  { artist: "Shinedown", songs: ["Sound Of Madness"] },
  { artist: "Whitesnake", songs: ["Love Ain't No Stranger"] },
  { artist: "Pearl Jam", songs: ["State of Love and Trust"] },
  { artist: "Pixies", the: true, songs: ["Where Is My Mind"] },
  { artist: "Bush", songs: ["Machinehead"] },
  { artist: "Aerosmith", songs: ["Come Together"] },
  { artist: "Allman Brothers", songs: ["Whipping Post"] },
  { artist: "Slade", songs: ["Run Runaway"] },
  { artist: "School of Fish", songs: ["3 Strange Days"] },
  { artist: "Collective Soul", songs: ["Shine"] },
  { artist: "Black Keys", the: true, songs: ["Lonely Boy"] },
  { artist: "Sublime", songs: ["What I Got"] },
  { artist: "Gary Numan", songs: ["Cars"] },
  { artist: "The Who", songs: ["Baba O'Reilly", "I Can't Explain"] },
]

const SetList = () => (
  <>
    <span className="image fit">
      <img src={jakeRZ} alt="" />
    </span>
    <h2 className="major">Growing List of Artists we Cover</h2>
    <div className="setlist">
      {sortBy(setList, ["artist"]).map(({ artist, the }, index) => {
        return (
          <span className={cx("artist", { even: index % 2 === 0 })}>
            {the && "The "}
            {artist}
          </span>
        )
      })}
    </div>
  </>
)

export default SetList
