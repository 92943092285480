import React from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import theBand from "../images/eof-ab-dec10.jpg"
import jake from "../images/jake-remy-zero.jpg"
import purdys from "../images/purdys-bw.jpg"
import aurora from "../images/aurora.JPG"
import auroraRed from "../images/aurora-red.jpeg"
import auroraSmoke from "../images/aurora-smoke.jpeg"
import michaelSkylark from "../images/michael-skylark.JPG"
import michaelSmoke from "../images/michael-smoke.jpg"
import jakeAir from "../images/jake-hands-in-the-air.JPG"
import eofLogo from "../images/eof-logo.jpg"
import skylark from "../images/skylark.JPG"
import fencePhoto from "../images/eof-fence.jpg"
import trioPhoto from "../images/eof-trio.jpeg"

import "./the-band.css"

const TheBand = () => (
  <div className="container">
    <div className="nextShow description">
      <b>
        Next Show: November 22nd, Aurora Borealis in Shoreline.
        <br />
        No Cover -- Doors at 7pm -- Music starts 7:30pm
      </b>
    </div>
    <div className="description">
      <b className="name">East of Friday</b> is a Seattle/Tacoma-based cover
      band playing hard rock, alternative, grunge, and classic rock covers.
      We're available for clubs, pubs, bars, taverns, events, and private
      parties for your rocking enjoyment! We're working on some originals too!
    </div>
    <br />
    <div className="member">
      <b className="name">Jake Nist</b> - Lead vox and bass
    </div>
    <div className="member">
      <b className="name">Michael Kintzer</b> - Lead guitar and backing vox
    </div>
    <div className="member">
      <b className="name">Stefan Perrin</b> - Sticks, drums, beats, motorcycles
    </div>
    <br />
    <b className="name">Honorary Member</b>
    <div className="member">
      <b className="name">Rick Boe (1963 -2024)</b> - Rhythm guitar, keyboards,
      backing vox
    </div>
    <Carousel
      className="carousel"
      autoPlay={true}
      interval={6000}
      infiniteLoop={true}
      showThumbs={false}
    >
      <div>
        <img src={trioPhoto} alt="Band Pic" />
      </div>
      <div>
        <img src={fencePhoto} alt="Previous Band Pic" />
      </div>
      <div>
        <img src={theBand} alt="the Band" />
      </div>
      <div>
        <img src={jakeAir} alt="Jake air" />
      </div>
      <div>
        <img src={auroraRed} alt="At Aurora Borealis" />
      </div>
      <div>
        <img src={auroraSmoke} alt="Smoke machine" />
      </div>
      <div>
        <img src={michaelSmoke} alt="Michael smoke" />
      </div>
      <div>
        <img src={jake} alt="Jake" />
      </div>
      <div>
        <img src={michaelSkylark} alt="Michael at Skylark" />
      </div>
      <div>
        <img src={skylark} alt="Skylark" />
      </div>
      <div>
        <img src={purdys} alt="at purdy's" />
      </div>
      <div>
        <img src={aurora} alt="More from Aurora Borealis" />
      </div>
      <div>
        <img src={eofLogo} alt="Our Logo" />
      </div>
    </Carousel>
  </div>
)

export default TheBand
